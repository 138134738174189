const { uniqueId } = require("lodash")

const POPULAIRES = [
    {
        title: "Mains - Pose de vernis",
        time: "10 min - 15 min",
        price: "à partir de 12 €",
        children: [
            {
                title: "Vernis coloré",
                time: "10 min",
                price: "12 €",
            },
            {
                title: "Vernis French",
                time: "15 min",
                price: "17 €",
            }
        ]
    },
    {
        title: "Manucure et pose de vernis semi-permanent",
        time: "35 min - 45 min",
        price: "à partir de 37 €",
        children: [
            {
                title: "Vernis semi-permanent coloré",
                time: "40 min",
                price: "37 €",
            },
            {
                title: "Vernis semi-permanent French",
                time: "45 min",
                price: "42 €",
            }
        ]
    },
]

const MANUCURES = [
    {
        title: "Mains - Pose de vernis",
        time: "10 min - 15 min",
        price: "à partir de 12 €",
        children: [
            {
                title: "Vernis coloré",
                time: "10 min",
                price: "12 €",
            },
            {
                title: "Vernis French",
                time: "15 min",
                price: "17 €",
            }
        ]
    },
    {
        title: "Mains - Pose de vernis semi-permanent",
        time: "20 min - 30 min",
        price: "à partir de 27 €",
        children: [
            {
                title: "Vernis couleur",
                time: "20 min",
                price: "27 €",
            },
            {
                title: "Vernis semi-permanent French",
                time: "30 min",
                price: "32 €",
            }
        ]
    },
    {
        title: "Manucure et pose de vernis",
        time: "30 min - 35 min",
        price: "à partir de 19 €",
        children: [
            {
                title: "Vernis coloré",
                time: "30 min",
                price: "19 €",
            },
            {
                title: "Vernis French",
                time: "35 min",
                price: "24 €",
            }
        ]
    },
    {
        title: "Manucure et pose de vernis semi-permanent",
        time: "35 min - 45 min",
        price: "à partir de 37 €",
        children: [
            {
                title: "Vernis semi-permanent coloré",
                time: "40 min",
                price: "37 €",
            },
            {
                title: "Vernis semi-permanent French",
                time: "45 min",
                price: "42 €",
            }
        ]
    },
    {
        title: "Homme - Manucure",
        time: "20 min",
        price: "19 €"
    },
    {
        title: "Mains - Dépose de vernis semi-permanent",
        time: "15 min",
        price: "5 €"
    },
    {
        title: "Mains - Baby Boomer, manucure et pose de vernis semi-permanent",
        time: "45 min",
        price: "50 €"
    },
]

const BEAUTE_DES_PIEDS = [
    {
        title: 'Pieds - Pose de vernis',
        time: '10 min - 15 min',
        price: 'à partir de 12 €',
        children: [
            {
                title: 'Vernis coloré',
                time: '10 min',
                price: '12 €',
            },
            {
                title: 'Vernis French',
                time: '15 min',
                price: '17 €',
            }
        ]
    },
    {
        title: 'Pieds - Pose de vernis semi-permanent',
        time: '20 min - 25 min',
        price: 'à partir de 27 €',
        children: [
            {
                title: 'Vernis coloré',
                time: '20 min',
                price: '27 €',
            },
            {
                title: 'Vernis semi-permanent French',
                time: '25 min',
                price: '32 €',
            },
        ]
    },
    {
        title: 'Beauté des pieds et pose de vernis',
        time: '40 min',
        price: 'à partir de 33 €',
        children: [
            {
                title: 'Vernis coloré',
                time: '40 min',
                price: '33 €',
            },
            {
                title: 'Vernis semi-permanent French',
                time: '40 min',
                price: '38 €',
            },
        ]
    },
    {
        title: 'Beauté des pieds et pose de vernis semi-permanent',
        time: '45 min',
        price: 'à partir de 48 €',
        children: [
            {
                title: 'Vernis semi-permanent coloré',
                time: '45 min',
                price: '48 €',
            },
            {
                title: 'Vernis semi-permanent French',
                time: '45 min',
                price: '53 €',
            }
        ]
    },
    {
        title: 'Pieds - Dépose de vernis semi-permanent',
        time: '10 min',
        price: '5 €'
    },
    {
        title: 'Homme - Beauté des pieds',
        time: '35 min',
        price: '33 €'
    },
]

const MANUCURES_PEDICURES = [
    {
        title: 'Homme - Manucure et beauté des pieds',
        time: '1 h',
        price: '47 €'
    },
    {
        title: 'Manucure, beauté des pieds et pose de vernis',
        time: '1 h 15 min - 1 h 20 min',
        price: 'à partir de 48 €',
        children: [
            {
                title: 'Vernis coloré',
                time: '1 h 15 min',
                price: '48 €',
            },
            {
                title: 'Vernis French',
                time: '1 h 20 min',
                price: '53 €',
            }
        ]
    },
    {
        title: 'Femme - Manucure et beauté des pieds',
        time: '40 min',
        price: '48 €'
    },
    {
        title: 'Manucure, beauté des pieds et pose de vernis semi-permanent',
        time: '1 h 30 min',
        price: 'à partir de 80 €',
        children: [
            {
                title: 'Vernis semi-permanent coloré',
                time: '1 h 30 min',
                price: '80 €',
            },
            {
                title: 'Vernis semi-permanent French',
                time: '1 h 30 min',
                price: '90 €',
            }
        ]
    },
    {
        title: 'Femme - Suppléments Perfect Spa',
        time: '5 min',
        price: 'à partir de 5 €',
        children: [
            {
                title: 'Serviette chaude - Mains et pieds',
                time: '5 min',
                price: '5 €',
            },
            {
                title: 'Gommage - Mains et pieds',
                time: '5 min',
                price: '5 €',
            },
            {
                title: 'Serviette chaude - Mains ou pieds',
                time: '5 min',
                price: '5 €',
            },
            {
                title: 'Gommage - Mains ou pieds',
                time: '5 min',
                price: '15 €',
            },
        ]
    },
]

const FAUX_ONGELS = [
    {
        title: "Dépose de faux ongles",
        time: "20 min",
        price: "15 €"
    },
    {
        title: "Gel - Pose d'ongles et pose vernis",
        time: "55 min - 1 h",
        price: "A partir de 55 €",
        children: [
            {
                title: "Vernis coloré",
                time: "55 min",
                price: "55 €"
            },
            {
                title: "Vernis French",
                time: "1 h",
                price: "60 €"
            }
        ]
    },
    {
        title: "Gel - Pose d'ongles avec capsules et pose de vernis",
        time: "1 h",
        price: "A partir de 55 €",
        children: [
            {
                title: "Gel coloré",
                time: "1 h",
                price: "55 €"
            },
            {
                title: "Gel French",
                time: "1 h",
                price: "60 €"
            }
        ]
    },
    {
        title: "Gel - Remplissage d'ongles et pose de vernis",
        time: "1 h",
        price: "A partir de 45 €",
        children: [
            {
                title: "Vernis coloré",
                time: "50 min",
                price: "45 €"
            },
            {
                title: "Vernis French",
                time: "1 h",
                price: "50 €"
            }
        ]
    },
    {
        title: "Gel - Pose d'ongles et pose de vernis semi-permanent",
        time: "1 h",
        price: "A partir de 65 €",
        children: [
            {
                title: "Vernis coloré",
                time: "1 h",
                price: "65 €"
            },
            {
                title: "Vernis French",
                time: "1 h",
                price: "70 €"
            }
        ]
    },
    {
        title: "Gel - Remplissage d'ongles et pose de vernis semi-permanent",
        time: "1 h",
        price: "A partir de 55 €",
        children: [
            {
                title: "Vernis coloré",
                time: "1 h",
                price: "55 €"
            },
            {
                title: "Vernis French",
                time: "1 h",
                price: "60 €"
            }
        ]
    },
    {
        title: "Réparation d'un ongle cassé",
        time: "10 min",
        price: "6 €"
    },
    {
        title: "Résine - Pose d'ongles et pose de vernis",
        time: "1 h",
        price: "A partir de 45 €",
        children: [
            {
                title: "Vernis coloré",
                time: "1 h",
                price: "45 €"
            },
            {
                title: "Vernis French",
                time: "1 h",
                price: "50 €"
            }
        ]
    },
    {
        title: "Résine - Pose d'ongles avec chablon et pose de vernis",
        time: "1 h 10 min",
        price: "A partir de 60 €",
        children: [
            {
                title: "Vernis coloré",
                time: "1 h",
                price: "60 €"
            },
            {
                title: "Vernis French",
                time: "1 h",
                price: "65 €"
            }
        ]
    },
    {
        title: "Résine - Remplissage d'ongles et pose de vernis",
        time: "1 h",
        price: "A partir de 35 €",
        children: [
            {
                title: "Vernis coloré",
                time: "1 h",
                price: "35 €"
            },
            {
                title: "Vernis French",
                time: "1 h",
                price: "40 €"
            }
        ]
    },
    {
        title: "Résine - Pose d'ongles et pose de vernis semi-permanent",
        time: "1 h",
        price: "A partir de 55 €",
        children: [
            {
                title: "Vernis coloré",
                time: "1 h",
                price: "55 €"
            },
            {
                title: "Vernis French",
                time: "1 h",
                price: "60 €"
            }
        ]
    },
    {
        title: "Résine - Remplissage d'ongles et pose de vernis semi-permanent",
        time: "1 h",
        price: "A partir de 40 €",
        children: [
            {
                title: "Vernis coloré",
                time: "1 h",
                price: "45 €"
            },
            {
                title: "Vernis French",
                time: "1 h",
                price: "50 €"
            }
        ]
    },
    {
        title: "Résine - Pose d'ongles Ombré Dégradé 2 couleurs",
        time: "1 h 10 min",
        price: "60 €"
    },
    {
        title: "Résine - Remplissage d'ongles Ombré Dégradé 2 couleurs",
        time: "40 min",
        price: "50 €"
    },
    {
        title: "Nail art",
        time: "5 min",
        price: "A partir de 0,5 €",
        children: [
            {
                title: "Décoration avec diamant - À l'unité",
                time: "5 min",
                price: "0,5 €"
            },
            {
                title: "Autre décoration (selon votre modèle) - 1 doigt",
                time: "5 min",
                price: "2 €"
            },
            {
                title: "Décoration avec autocollant - 1 doigt",
                time: "5 min",
                price: "2 €"
            },
            {
                title: "Supplément couleur",
                time: "5 min",
                price: "À partir de 5 €"
            },
            {
                title: "Effet chrome miroir en vernis semi-permanent",
                time: "5 min",
                price: "10 €"
            },
        ]
    },
    {
        title: "Gel - Ponçage d'ongles et pose vernis",
        time: "20 min",
        price: "25 €"
    },
    {
        title: "Gel - Ponçage d'ongles et pose de vernis semi-permanent",
        time: "40 min",
        price: "37 €"
    },
    {
        title: "Résine - Ponçage d'ongles et pose de vernis",
        time: "30 min",
        price: "25 €"
    },
    {
        title: "Résine - Ponçage d'ongles et pose de vernis semi-permanent",
        time: "40 min",
        price: "37 €"
    },
]

const flatten = (arr, results) => {
    arr.forEach(s => {
        if(!s.children) {
            let obj = Object.assign({}, s);
            obj.description = s.title + ' | ' + s.price;
            obj.name = s.title;
            obj.id = uniqueId();
            results.push(obj);
        }
        else {
            s.children.forEach(y => {
                let obj = Object.assign({}, y);
                obj.description = s.title + ' | ' + y.title + ' | ' + y.price;
                obj.name = s.title + ' | ' + y.title;
                obj.id = uniqueId();
                results.push(obj);
            });
        }
    })
}

const ALL = (() => {
    let results = [];
    flatten(MANUCURES, results);
    flatten(BEAUTE_DES_PIEDS, results);
    flatten(MANUCURES_PEDICURES, results);
    flatten(FAUX_ONGELS, results);
    return results.sort((s1, s2) => s1.description > s2.description ? 1 : -1);
})();

module.exports = {
    prestations: [
        { title: 'Manucure', data: MANUCURES },
        { title: 'Beauté Des Pieds', data: BEAUTE_DES_PIEDS },
        { title: 'Manucure Et Beauté Des Pieds', data: MANUCURES_PEDICURES },
        { title: 'Pose De Faux Ongles', data: FAUX_ONGELS },
    ],
    populaires: POPULAIRES,
    all_prestations: ALL
}